<template>
  <label 
    class="form-item"
    :class="{
      'form-item_error': isError
    }"
  >
    <div
      v-show="label || (isError && errorMessage)" 
      class="form-item__label form-item__label_flex"
      :class="{
        'form-item__label_error': isError
      }"
    >
      {{ label }}
      <slot name="header"></slot>
      <span 
        v-show="isError && errorMessage"
        class="form-item__message form-item__message_error form-item__message_desk"
      >
        {{ errorMessage }}
      </span>
    </div>
    <slot></slot>
    <div 
      v-show="isError && errorMessage"
      class="form-item__message form-item__message_error form-item__message_mobile"
    >
      {{ errorMessage }}
    </div>
    <slot name="footer"></slot>
  </label>
</template>

<script>
  export default {
    props: {
      isError: {
        type: Boolean,
        required: false
      },
      label: {
        type: String,
        required: false
      },
      errorMessage: {
        type: String,
        required: false
      }
    }
  }
</script>
