<template>
  <div class="preloader">
    <div class="preloader__content">
      <span class="preloader__message">
        {{ text }}
      </span>
      <span class="preloader__dots">
        <span class="preloader__dot"></span>
        <span class="preloader__dot"></span>
        <span class="preloader__dot"></span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: 'Загрузка'
    },
  }
}
</script>
