<template>
  <div class="input-wrap">
    <input 
      type="text"
      v-bind="$attrs"
      :value="modelValue" 
      @input="$emit('update:modelValue', $event.target.value)"
      class="input"
    >
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue']
}
</script>
