<template>
  <div class="file-input__wrap">
    <!-- input -->
    <label class="file-input">
      <input 
        @input="onFilesUpload"
        :multiple="multiple"
        :disabled="disabled"
        v-bind="$attrs"
        type="file" 
        class="file-input__native"
        name="file"
        ref="file"
      >
      <span class="file-input__custom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="currentColor" d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5a2.5 2.5 0 0 1 5 0v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5a2.5 2.5 0 0 0 5 0V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/>
        </svg>
        {{ label }}
      </span>
    </label>
    
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  emits: ['upload-files', 'total-files-size'],
  props: {
    label: {
      type: String,
      required: false,
      default: 'Выберите файл'
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalFilesSize: {
      type: Number,
      required: true,
    }
  },
  data() {
      return {}
  },
  methods: {
    onFilesUpload(event) {
      this.$emit('upload-files', event.target.files)
    },
    clearFilesList() {
      this.$refs.file.value = null
    }
  }
}
</script>

