<template>
  <div class="input-wrap">
    <input 
      :type="type"
      :value="modelValue" 
      @input="$emit('update:modelValue', $event.target.value)"
      class="input input_password"
      v-bind="$attrs"
      :disabled="disabled"
    >
    <button 
      @click.prevent="toggleType"
      type="button"
      class="input-wrap__pass-btn"
      :class="{
        crossed: type === 'text'
      }"
      :disabled="disabled"
    >
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      type: 'password'
    }
  },
  methods: {
    toggleType() {
      this.type = this.type === 'text' ? 'password' : 'text';
    }
  }

}
</script>
