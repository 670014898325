export function checkStringFilled(string) {
  return typeof string === 'string' && 
         string.length > 0
}

export function checkStringEmpty(string) {
  return typeof string === 'string' && 
         string.length === 0
}

export function checkStringLength(string, min, max) {
  return typeof string === 'string' && 
         string.length >= min && 
         string.length <= max
}

export function checkStringsEqual(first, second) {
  return typeof first === 'string' && 
         typeof second === 'string' &&
         first === second
}

export function checkStringsNotEqual(first, second) {
  return typeof first === 'string' && 
         typeof second === 'string' &&
         first !== second
}

export function checkType(value, type) {
  return typeof value === type
}

export function checkNumberRange(number, min, max) {
  return typeof number === 'number' && 
         number >= min &&
         number <= max
}

export function checkNumberMore(number, min) {
  return typeof number === 'number' && 
         number > min
}

export function checkNumbersEqual(first, second) {
  return typeof first === 'number' && 
         typeof second === 'number' && 
         first === second
}

export function checkArrayFilled(array) {
  return Array.isArray(array) &&
         array.length > 0
}

export function checkSlug(slug) {
  const regular = /^[a-z-]+$/i

  return typeof slug === 'string' && 
                slug.length > 0 &&
                regular.test(slug.toLowerCase())
}

export function checkName(name) {
  const regular = /^[a-zа-яё '"`«»-]+$/i
      
  return typeof name === 'string' && 
         regular.test(name.toLowerCase())
}

export function checkDate(date, min, max) {
  if (typeof date !== 'string') {
    return false
  }

  const value = date.replace(/[_]/gi, '')

  if (value.length !== 10) {
    return false
  }

  const dateArray = value.split('.')

  if (dateArray.length !== 3) {
    return false
  }

  const [day, month, year] = dateArray
  const dateFormated = `${year}-${month}-${day}`

  if (String(new Date(dateFormated)) === 'Invalid Date') {
    return false
  }

  if (new Date(dateFormated) > max) {
    return false
  }

  if (new Date(dateFormated) < min) {
    return false
  }

  return true
}

export function checkEmail(email) {
  const regular = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  
  return typeof email === 'string' && 
         regular.test(email.toLowerCase())
}

export function checkPhone(phone) {
  return typeof phone === 'string' && 
          /^\+[\d\(\)\ -]{10,18}\d$/.test(phone)
} 
