import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      popupName: state => state.popup.name,
    })
  },
  methods: {
    ...mapMutations({
      openPopup: 'popup/openPopup',
      closePopup: 'popup/closePopup'
    }),
    ...mapActions({
      openAlert: 'popup/openAlert'
    })
  }
}
