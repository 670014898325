<template>
  <label class="checkbox">
    <input 
      type="checkbox" 
      class="checkbox__native" 
      v-bind="$attrs"
      :checked="modelValue" 
      @input="$emit('update:modelValue', $event.target.checked)"
    >
    <button 
      type="button"
      class="checkbox__custom"
    ></button>
    <div v-if="text" class="checkbox__text checkbox__text_limited" v-html="text">
    </div>
    <div v-else class="checkbox__text checkbox__text_limited">
      <slot></slot>
    </div>
  </label>
</template>

<script>

export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      required: false
    },
    text: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue']
}
</script>
