<template>
  <div class="input-wrap">
    <input 
      type="number" 
      class="input"
      v-bind="$attrs"
      :value="modelValue" 
      @input="onInput"
    >
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onInput(e) {
      if (e.target.max && +e.target.value > +e.target.max) {
        e.target.value = e.target.max
      }

      this.$emit('update:modelValue', e.target.value)
    }
  }
}
</script>
