export default {
  namespaced: true,
  state: {
		name: '',
    isActive: false,
    popupClasses: [],
    contentClasses: [],
    btnClasses: [],
    bodyClasses: [],
    onClose: () => {},
    alertText: '' 
	},
  mutations: {
    openPopup(state, {name, contentClasses, btnClasses, popupClasses, bodyClasses, onClose}) {
      state.name = name
      state.isActive = true
      state.contentClasses = contentClasses || []
      state.btnClasses = btnClasses || []
      state.popupClasses = popupClasses || []
      state.bodyClasses = bodyClasses || []
      if (typeof onClose === 'function') {
        state.onClose = onClose
      }
      document.body.style.overflow = 'hidden'
    },
    closePopup(state) {
      state.name = ''
      state.isActive = false
      state.contentClasses = []
      state.btnClasses = []
      state.popupClasses = []
      state.bodyClasses = []
      state.onClose()
      state.onClose = () => {}
      document.body.style.overflow = ''
    },
    setAlertText(state, value) {
      state.alertText = value
    }
	},
  actions: {
    openAlert({commit}, text) {
      // alert используется для отображения сообщений, например об ошибке / успехе и лежит в корне приложения. В сдучае ошибки при получении get запроса показывается автоматический. Так же можно вызвать вручную.
      commit('setAlertText', text)
      commit('openPopup', {
        name: 'alert',
        contentClasses: ['popup__content_small popup__content_center']
      })
    }
  }
}
