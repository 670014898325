<template>
  <div 
    v-show="isActive"
    class="popup"
    :class="popupClasses"
    @click="closePopupClickHandler"
  >
    <div 
      class="popup__body"
      :class="bodyClasses"
    >
      <div 
        class="popup__content" 
        :class="contentClasses"
        id="popup"
        data-popup-wrapper
      >
        <button 
          class="popup__close" 
          :class="btnClasses"
          type="button" 
          @click="closePopup"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import popupMixin from '@com/mixins/popup'

export default {
  mixins: [popupMixin],
  computed: {
    ...mapState({
      isActive: state => state.popup.isActive,
      contentClasses: state => state.popup.contentClasses,
      btnClasses: state => state.popup.btnClasses,
      popupClasses: state => state.popup.popupClasses,
      bodyClasses: state => state.popup.bodyClasses
    })
  },
  methods: {
    closePopupClickHandler(event) {
      if (!event.target.closest('[data-popup-wrapper]')) {
        this.closePopup()
      }
    },
    escKeyDownHandler(event) {
      if ((event.key === 'Escape' || event.key === 'Esc') && this.isActive) {
        this.closePopup()
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.escKeyDownHandler)
  },
  unmounted() {
    document.removeEventListener('keydown', this.escKeyDownHandler)
  }
}
</script>
