<template>
  <select 
    class="select" 
    v-bind="$attrs"
    :value="modelValue" 
    @input="$emit('update:modelValue', $event.target.value)"
  >
    <option v-if="placeholder" :value="placeholder.value" class="select__option" selected disabled>
      {{ placeholder.text }}
    </option>
    <option v-if="resetOption" :value="resetOption.value" class="select__option">
      {{ resetOption.text }}
    </option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      class="select__option"
    >
      {{ option.text }}
    </option>
  </select>
</template>

<script>
  export default {
    inheritAttrs: false,
    props: {
      modelValue: {
        type: String,
        required: false
      },
      options: {
        type: Array,
        required: false
      },
      resetOption: {
        type: Object,
        required: false
      },
      placeholder: {
        type: Object,
        required: false
      }
    },
    emits: ['update:modelValue']
  }
</script>
